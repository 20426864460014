<template>
  <v-container id="tasks" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col>
                <div class="display-2 font-weight-light" style="text-transform: uppercase">
                  <h4>{{task.taskName}}</h4>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4" sm="4">
                <v-chip
                  v-on:click="activateItemByButton(task.taskId,task.taskActive)"
                  :color="task.taskActive===true?'#3F51B5':'#607D8B'"
                  text-color="white"
                  style="float: right;text-transform: uppercase"
                  label>
                  <h2>{{task.taskActive===true?$t('task-page.task-open'):$t('task-page.task-complete')}}</h2>
                </v-chip>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-auto" v-if="task.order !==null && task.order !==undefined">
              <v-col>
                <div class="subtitle-1 font-weight-light">
                  {{$t('task-page.order')}}: <h3>{{task.order.orderCode}}</h3>
                </div>
              </v-col>
              <v-col>
                <div class="subtitle-1 font-weight-light" style="float: right">
                  {{$t('task-page.agency')}}: <h3>{{task.order.agency.agencyName}}</h3>
                </div>
              </v-col>
            </v-row>

            <v-row class="ma-auto">
              <v-col>
                <div class="subtitle-1 font-weight-light">
                  {{$t('task-page.start-time')}}: <h3>{{parseTime(task.startTime)}}</h3>
                </div>
              </v-col>
              <v-col>
                <div class="subtitle-1 font-weight-light" style="float: right">
                  {{$t('task-page.due-time')}}: <h3>{{parseTime(task.dueTime)}}</h3>
                </div>
              </v-col>
            </v-row>

            <v-row class="ma-auto">
              <v-col>
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col>
                <ActionButtonGroup v-on:enable-edit="disabled=false" v-on:open-delete-dialog="openDeleteDialog()"
                                   v-on:clone-dialog="cloneDialog=true"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <validation-observer
            ref="observer"
            v-slot="{ invalid }">
            <v-form @submit.prevent="update">
              <v-container fluid>
                <v-row dense>
                  <v-tabs grow icons-and-text center-active centered show-arrows>
                    <v-tab v-for="card in cards" :key="card.id">{{card.title}}
                      <v-icon :color="card.color">{{card.icon}}</v-icon>
                    </v-tab>
                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <validation-provider rules="required" v-slot="{ errors }">
                              <v-text-field required
                                            :label="$t('task-page.task')" v-model="task.taskName" :readonly="disabled"/>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-switch v-model="task.taskActive" inset
                                      v-on:change="activateItem(task.taskId, task.taskActive)" :label="$t('task-page.task-active')">
                            </v-switch>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>

                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }">
                              <v-text-field :label="$t('task-page.description')" v-model="task.description" :readonly="disabled"/>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>

                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }">
                              <v-datetime-picker v-model="task.startTime" :label="$t('task-page.start-time')"
                                                 :timePickerProps="timePickerProps"
                                                 :date-picker-props="datePickerProps" :text-field-props="textFieldProps"
                                                 :readonly="disabled">
                              </v-datetime-picker>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }">
                              <v-datetime-picker v-model="task.dueTime" :label="$t('task-page.due-time')"
                                                 :timePickerProps="timePickerProps"
                                                 :date-picker-props="datePickerProps" :text-field-props="textFieldProps"
                                                 :readonly="disabled">
                              </v-datetime-picker>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>

                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('task-page.created-date')" v-model="createdTime" readonly/>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('task-page.last-modified')" v-model="modifiedTime" readonly/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>
                  </v-tabs>
                </v-row>
              </v-container>
              <v-row>
                <v-col
                  cols="6"
                  class="text-right">
                  <v-btn color="primary" class="mr-0" @click="disabled=true" :disabled="disabled" block>
                    {{$t('buttons.cancel')}}
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right">
                  <v-btn color="primary" class="mr-0" @click="update" :disabled="invalid" block>
                    {{$t('buttons.update')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import OrderRelatedRecords from "../../components/expansion-panels/OrderRelatedRecords";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  let moment = require('moment');
  export default {
    name: "Task Card",
    components: {
      DeleteConfirmationDialog,
      MenuDropdown,
      ActionButtonGroup, OrderRelatedRecords, ValidationProvider, ValidationObserver
    },
    data() {
      return {
        cards: [
          {id: 1, title: this.$t('task-card-tabs.main-info'), color: 'indigo', flex: 12, icon: 'mdi-folder-information-outline'},
          {id: 2, title: this.$t('task-card-tabs.description'), color: 'pink darken-3', flex: 12, icon: 'mdi-comment-processing-outline'},
          {id: 3, title: this.$t('task-card-tabs.dates'), color: 'green', flex: 12, icon: 'mdi-calendar-clock'},
          {id: 4, title: this.$t('task-card-tabs.system-info'), color: 'purple darken-4', flex: 12, icon: 'mdi-table-cog'},
        ],
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline',
          clearable: true
        },
        deleteDialog: false,
        cloneDialog: false,
        panelNames: ['Orders'],
        task: [],
        objectName: 'isTask',
        disabled: true,
        createdTime: '',
        modifiedTime: '',
        fileCreatedTime: '',
        fileModifiedTime: '',
        errors: []
      }
    },
    methods: {
      activateItem(taskId, active) {
        let action = 'update';
        let objectType = 'Task';
        let params = new URLSearchParams();
        params.append('taskActive', active);
        params.append('taskId', taskId);
        AXIOS.put(`/api/activateTask/`, params)
          .then(response => {
            this.response = response.data;
            this.userNotification(response.data, action, objectType);
          })
      },
      activateItemByButton(taskId, active) {
        let action = 'update';
        let objectType = 'Task';
        active = !active;
        let params = new URLSearchParams();
        params.append('taskActive', active);
        params.append('taskId', taskId);
        AXIOS.put(`/api/activateTask/`, params)
          .then(response => {
            this.getTaskById();
            this.userNotification(response.data, action, objectType);
          })
      },
      parseTime(time) {
        let task = this.task;
        let format = 'YYYY-MM-DD HH:mm';
        this.createdTime = moment(task.createdTime).format("DD.MM.YYYY, HH:mm");
        this.modifiedTime = moment(task.modifiedTime).format("DD.MM.YYYY, HH:mm");
        task.startTime = moment(task.startTime).format(format);
        task.dueTime = moment(task.dueTime).format(format);
        return time = moment(time).format("DD.MM.YYYY HH:mm")
      },
      getTaskById() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        AXIOS.get('/api/task/' + this.$route.params.taskId, {params})
          .then(response => {
            this.task = response.data;
            this.parseTime();
            this.loading = false;
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      openDeleteDialog() {
        this.deleteDialog = true;
      },
      deleteObject() {
        let action = 'delete';
        let objectType = 'Task';
        let params = new URLSearchParams();
        params.append('taskId', this.$route.params.taskId);
        AXIOS.delete('/api/deleteTask', {params});
        this.userNotification(action, objectType);
        this.$router.push({name: 'all-tasks'})
      },
      update() {
        let action = 'update';
        let objectType = 'Task';
        let task = this.task;
        let format = "YYYY-MM-DDTHH:mm:ss";
        let startTime = moment(task.startTime).format(format);
        let dueTime = moment(task.dueTime).format(format);
        let params = new URLSearchParams();
        params.append('taskId', task.taskId);
        params.append('taskName', task.taskName);
        params.append('description', task.description);
        params.append('startTime', startTime);
        params.append('dueTime', dueTime);
        params.append('taskActive', task.taskActive);
        AXIOS.put(`/api/updateTask/`, params)
          .then(response => {
            this.response = response.data;
            this.getTaskById();
            this.userNotification(response.data, action, objectType);
            this.disabled = true;
          })
      },
    },
    created() {
      this.getTaskById();
    },
  }
</script>
<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
